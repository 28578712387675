<!--物业服务费收支-->
<template>
  <page-container title="公告" :breadcrumb="breadcrumb">
    <!--    查询栏-->
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="userdepid" style="width: 25%">
              <a-cascader  :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" v-model="selectDeptList" :options="deptOptions?deptOptions.filter(item => item.deptype===1):[]" :load-data="loadChildrenDept" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
<!--            <a-form-model-item label="类型" prop="notice_type">-->
<!--              <a-select v-model="queryParams.notice_type">-->
<!--                <a-select-option value="">全部</a-select-option>-->
<!--                <a-select-option :value="1">通知</a-select-option>-->
<!--                <a-select-option :value="2">公告</a-select-option>-->
<!--              </a-select>-->
<!--            </a-form-model-item>-->
            <a-form-model-item label="主题" prop="topic" style="width: 25%">
              <a-input v-model="queryParams.topic"></a-input>
            </a-form-model-item>
            <a-form-model-item label="内容" prop="content" style="width: 25%">
              <a-input v-model="queryParams.content"></a-input>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 20%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开
                <a-icon :type="showAdvanced?'up':'down'"></a-icon>
              </a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="是否作废" prop="is_history" style="width: 25%">
              <a-select v-model="queryParams.is_history">
                <a-select-option value="">全部</a-select-option>
                <a-select-option :value="0">否</a-select-option>
                <a-select-option :value="1">是</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="是否失效" prop="is_invalid" style="width: 25%">
              <a-select v-model="queryParams.is_invalid">
                <a-select-option value="">全部</a-select-option>
                <a-select-option :value="0">否</a-select-option>
                <a-select-option :value="1">是</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <!--    展示列表栏-->
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">公告列表</h3>
          <div class="table-btns">
            <a-button type="primary" @click="showModal('add')"><a-icon type="plus"></a-icon>新建</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="id" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false"  :customRow="handleClickRow">
          <span slot="notice_type" slot-scope="value">
            <span class="text">{{ noticeTypes[value] }}</span>
          </span>
          <span slot="is_history" slot-scope="value">
            <span class="text">{{ value==='1'?'是':'否'}}</span>
          </span>
          <span slot="is_invalid" slot-scope="value">
            <span class="text">{{ value==='1'?'是':'否'}}</span>
          </span>
          <span slot="start_time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="end_time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="create_time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.id">详情</a-menu-item>
<!--                <a-menu-item :key="'edit-'+record.id">修改</a-menu-item>-->
                <a-menu-item :key="'delete-'+record.id">作废</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <!--    新建、修改公告页面-->
    <a-modal v-model="modalVisible" :title="modalTitle" :width="715" :dialog-style="{top: '25px'}" :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalCancel">{{modalType==='detail'?'关闭':'取消'}}</a-button>
        <a-button v-if="modalType==='add'||modalType==='edit'"  type="primary" @click="modalConfirm">提交</a-button>
      </template>
      <a-form-model ref="modalForm" :model="modalForm" :rules="modalRules" :label-col="{span:8}" :wrapper-col="{span:12}">
        <a-form-model-item label="类型" prop="notice_type">
          <a-select :disabled="true" style="width: 330px" v-model="modalForm.notice_type">
            <a-select-option :value="1">通知</a-select-option>
            <a-select-option :value="2">公告</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="modalType!=='detail'&&selectUserDep" label="公司/项目" prop="userdep_ids">
          <a-tree-select
              style="width: 330px"
              :disabled="modalType==='detail'"
              labelInValue
              checkStrictly
              checkable
              tree-checkable
              v-model="selectedUserDepIds"
              :replace-fields="{children:'children', title:'userdepname', value:'userdepid',key:'userdepid'}"
              :default-expand-all="false"
              :tree-data="userDepList"
              :show-checked-strategy="SHOW_ALL()"
          ></a-tree-select>
        </a-form-model-item>
        <a-form-model-item label="生效时间" prop="start_time">
          <a-date-picker :disabled="modalType==='detail'" style="width: 330px" v-model.trim="modalForm.start_time" show-time placeholder="请选择日期和时间"></a-date-picker>
        </a-form-model-item>
        <a-form-model-item label="失效时间" prop="end_time">
          <a-date-picker :disabled="modalType==='detail'" style="width: 330px" v-model.trim="modalForm.end_time" show-time placeholder="请选择日期和时间"></a-date-picker>
        </a-form-model-item>
        <a-form-model-item label="主题" prop="topic">
          <a-input :disabled="modalType==='detail'" style="width: 330px" v-model="modalForm.topic"></a-input>
        </a-form-model-item>
        <a-form-model-item label="内容" prop="content">
          <a-textarea :disabled="modalType==='detail'" style="width: 330px" v-model="modalForm.content"></a-textarea>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </page-container>
</template>
<script>
import {
  addPerformanceNotification,
  getNotificationListByCondition,
  deleteOneNotification,
  editNotification, setNotificationHistory
} from 'A/performance'
import {
  getItemFromArrayByKey,
} from 'U'
import moment from 'moment'
import deptselect from '@/mixins/deptselect'
import {userdep} from "V/dataAnalysis/performance/minins/userdep";
import {SHOW_ALL} from "ant-design-vue/lib/vc-tree-select";
import {getTreeOfUserDepHasUser, getUserdepListByCondition} from "A/jcgn";
import {mapState} from "vuex";
export default {
  name: 'announcement',
  mixins: [deptselect,userdep],
  data() {
    return {
      showAdvanced: false,
      moment,
      noticeTypes:["","通知","公告"],
      modalBodyStyle: {
        maxHeight: '560px',
        overflowY: 'auto',
      },
      breadcrumb: [
        {
          name: '业务管理',
          path: ''
        },
        {
          name: '通知公告',
          path: ''
        },
        {
          name: '公告',
          path: ''
        }
      ],
      queryParams: {
        notice_type:2,
        is_history:0,
        is_invalid:0,
        userdepid:'',
        start_time:"",
        end_time:"",
      },
      tableColumns: [
        // {
        //   title: '公司/项目',
        //   dataIndex: 'userdepname',
        //   key: 'userdepname'
        // },
        {
          title: '类型',
          dataIndex: 'notice_type',
          key: 'notice_type',
          scopedSlots: {customRender: 'notice_type'}
        },
        {
          title: '主题',
          dataIndex: 'topic',
          key: 'topic',
          scopedSlots: {customRender: 'topic'}
        },
        {
          title: '内容',
          dataIndex: 'content',
          key: 'content',
          ellipsis: true,
          scopedSlots: {customRender: 'content'}
        },
        {
          title: '公司/项目',
          dataIndex: 'userdep_names',
          key: 'userdep_names',
          ellipsis: true,
          scopedSlots: {customRender: 'userdep_names'}
        },
        {
          title: '生效时间',
          dataIndex: 'start_time',
          key: 'start_time',
          scopedSlots: {customRender: 'start_time'}
        },
        {
          title: '失效时间',
          dataIndex: 'end_time',
          key: 'end_time',
          scopedSlots: {customRender: 'end_time'}
        },
        {
          title: '上传时间',
          dataIndex: 'create_time',
          key: 'create_time',
          scopedSlots: {customRender: 'create_time'}
        },
        {
          title: '是否失效',
          dataIndex: 'is_invalid',
          key: 'is_invalid',
          ellipsis: true,
          scopedSlots: {customRender: 'is_invalid'}
        },
        {
          title: '是否作废',
          dataIndex: 'is_history',
          key: 'is_history',
          ellipsis: true,
          scopedSlots: {customRender: 'is_history'}
        },
        {
          title: '操作',
          key: 'operation',
          width:70,
          scopedSlots: {customRender: 'operation'}
        },
      ],
      tableData: [],
      tableLoading: false,
      selectDeptList: [],
      currentPage: 1,
      pagination: {
        total: 0,
        pageSize: 10,
      },
      modalVisible: false,
      modalType: '',
      modalForm: {
        id:0,
        notice_type:'',
        is_history:0,
        topic:'',
        content:'',
        userdep_ids: '',
        userdep_names: '',
        start_time:"",
        end_time:"",
      },
      modalRules: {
        notice_type: [{required: true, message: '请选择通知公告类型'}],
        userdep_ids: [{required: true, message: '请选择公司/项目'}],
        topic: [{required: true, message: '请输入主题'}],
        content: [{required: true, message: '请输入内容'}],
        start_time: [{required: true, message: '请选择生效时间'}],
        end_time: [{required: true, message: '请选择失效时间'}]
      },
      selectedNotification:null,
      userDepList:[],
      selectUserDep:true,
      selectedUserDepIds:null,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    modalTitle() {
      let title = '';
      if (this.modalType == 'add') {
        title = '新增';
      } else if (this.modalType == 'detail') {
        title = '详情';
      } else if (this.modalType == 'edit') {
        title = '修改';
      } else {
        title = '';
      }
      return title;
    },
  },
  watch: {
    selectDeptList(val) {
      if(val && val.length) {
        this.queryParams.userdepid = val[val.length-1];
      }else {
        this.queryParams.userdepid = '';
      }
    },
    'selectedUserDepIds'(val) {
      if(val && val.length) {
        this.modalForm.userdep_ids=""
        this.modalForm.userdep_names=""
        for(let i=0;i<val.length;i++){
          this.modalForm.userdep_ids+=val[i].value+","
          this.modalForm.userdep_names+=val[i].label+","
        }
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    SHOW_ALL() {
      return SHOW_ALL
    },
    init() {
      this.getTableData();
      this.getAllUserDepList()
    },
    resetQueryParams() {
      this.selectDeptList=[]
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize,
      };
      getNotificationListByCondition(params).then(res => {
        this.tableLoading = false;
        if (res && res.returncode === '0') {
          this.tableData = res.item
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    pageChange(page, size) {
      this.getTableData();
    },
    onShowSizeChange(page, size) {
      this.pagination.pageSize = size;
      this.getTableData(true);
    },
    showModal(type, value, record) {
      this.modalType = type;
      if (type === 'add') {
        this.modalVisible = true;
        this.modalForm.notice_type=this.queryParams.notice_type
        this.modalForm.is_history=0
        this.modalForm.topic=''
        this.modalForm.content=''
        this.modalForm.userdep_ids= !this.selectUserDep?this.modalForm.userdep_ids:''
        this.modalForm.userdep_names= !this.selectUserDep?this.modalForm.userdep_names:''
        this.modalForm.start_time= null
        this.modalForm.end_time= null
        this.selectedUserDepIds=null
      } else {
        let selectedUserDepIds = []
        let userdep_ids=record.userdep_ids.split(",")
        let userdep_names=record.userdep_names.split(",")
        for(let i=0;i<userdep_ids.length-1;i++) {
          selectedUserDepIds.push({label: userdep_names[i], value: userdep_ids[i]})
        }
        this.selectedUserDepIds=selectedUserDepIds
        this.modalForm.id=record.id
        this.modalForm.notice_type=record.notice_type
        this.modalForm.is_history=record.is_history
        this.modalForm.topic=record.topic
        this.modalForm.content=record.content
        this.modalForm.userdep_ids=!this.selectUserDep?this.modalForm.userdep_ids:''
        this.modalForm.userdep_names=!this.selectUserDep?this.modalForm.userdep_names:''
        this.modalForm.start_time=moment(record.start_time,"YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss")
        this.modalForm.end_time=moment(record.end_time,"YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss")
        this.modalVisible = true;
      }
    },
    getAllUserDepList(){
      getTreeOfUserDepHasUser({}).then(res=>{
        if(res&&res.returncode==='0'){
          this.userDepList=res.item
          if(this.userDepList.length===0){
            this.selectUserDep=false
            getUserdepListByCondition({userdepid:this.userInfo.userdepid}).then(res=>{
              if(res&&res.returncode==='0'){
                this.modalForm.userdep_ids=this.userInfo.userdepid+","
                this.modalForm.userdep_names=res.item[0].userdepname+","
              }
            })
          }
        }
      })
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.id);
      }).catch(()=>{
      });
    },
    delete(notification_id) {
      if (notification_id) {
        let params = {
          id:notification_id
        }
        setNotificationHistory(params).then(res => {
          if (res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          } else {
            this.$message.error(res.errormsg || '操作失败');
          }
        })
      }
    },
    modalConfirm() {
      if (this.modalType === 'add' || this.modalType === 'edit') {
        this.$refs.modalForm.validate(valid => {
          if (valid) {
            this.addOrEdit();
          } else {
            return false;
          }
        })
      } else {
        this.modalVisible = false;
      }
    },
    //添加或者编辑物业收支单
    addOrEdit() {
      this.showLoading();
      this.modalForm.start_time=moment(this.modalForm.start_time).format("YYYYMMDDHHmmss")
      this.modalForm.end_time=moment(this.modalForm.end_time).format("YYYYMMDDHHmmss")
      let params = {
        notice_type: this.modalForm.notice_type,
        is_history: this.modalForm.is_history,
        topic:this.modalForm.topic,
        content:this.modalForm.content,
        userdep_ids:this.modalForm.userdep_ids,
        userdep_names:this.modalForm.userdep_names,
        start_time: this.modalForm.start_time,
        end_time: this.modalForm.end_time,
      };
      if (this.modalType === 'add') {
        addPerformanceNotification(params).then(this.addOrEditResult);
      } else if (this.modalType === 'edit') {
        params.id = this.modalForm.id;
        editNotification(params).then(this.addOrEditResult)
      }
    },
    addOrEditResult(res) {
      this.hideLoading();
      if (res && res.returncode === '0') {
        this.$message.success('操作成功');
        this.modalVisible = false;
        this.getTableData();
      } else {
        this.$message.error(res.errormsg || '操作失败');
      }
    },
    modalCancel() {
      this.modalVisible = false;
    },
    resetModal() {
      this.$refs.modalForm.resetFields();
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let id = arr[1];
      this.selectedNotification = getItemFromArrayByKey(this.tableData, 'id', id);
      if ('edit' == type) {
        this.showModal('edit', id, this.selectedNotification)
      } else if ('detail' == type) {
        this.showModal('detail', id, this.selectedNotification)
      }else if ('delete' == type) {
        this.deleteConfirm(id, this.selectedNotification)
      }
    },
    handleClickRow(record,index){
      return {
        on: {
          click: () => {
            this.operationClick({key:"detail-"+String(record.id)});
          }
        }
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.menu-modal-container {
  // display: flex;
  // position: relative;
  height: 100%;
  overflow-y: auto;
  margin-right: -24px;
}
.file_path-view {
  display: flex;
  align-items: center;
  .text {
    display: inline-block;
    margin-left: 7px;
    //border: 1px solid black;
    color: #57c921;
    &.red {
      color: #e70c0c;
    }
  }
}
</style>